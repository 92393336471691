import React from 'react'
import { Link } from 'gatsby'
import logo from '../media/logo.jpg'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { FaSearch } from 'react-icons/fa'
import DrawOrder from '../../static/media/pathlab-draw-order.pdf'

const navHandler = toggleNav => () => document.querySelector('.navbar-menu').classList.toggle('is-active')

const Navbar = ({ toggleNav }) => (
  <nav className="navbar is-transparent is-fixed-top">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item">
          <figure className="image">
            <img src={logo} alt="Pathlab tests logo" style={{ width: '200px', maxHeight: '4em' }} />
          </figure>
        </Link>
        <div className="navbar-burger" role="button" aria-label="menu" aria-expanded="false" data-target="pathlabNavbar" onClick={navHandler(toggleNav)}>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </div>
      </div>
      <div id="pathlabNavbar" className="navbar-menu">
        <div className="navbar-start">
          <Link className="navbar-item" to="/" activeClassName="is-active">
            <FaSearch /> &nbsp; Search Tests
          </Link>
          <div className="navbar-item is-hoverable">
            <a href={DrawOrder} target="_blank" rel="noopener noreferrer" className="navbar-item">Specimen Draw Order</a>
          </div>
          <div className="navbar-item is-hoverable">
            <a className="navbar-item" href="https://www.pathlab.co.nz">
              <FaArrowCircleLeft /> &nbsp; Return to Main Site
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
)

export default Navbar
